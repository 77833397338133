import { lazy } from 'react'

// Check localStorage availability once and store the result
const localStorageAvailable = (() => {
  try {
      const testKey = "__test__"
      window.localStorage.setItem(testKey, "test")
      window.localStorage.removeItem(testKey)
      return true
  } catch (error) {
      return false
  }
})()

// Global flag to track if the alert has been shown
let alertShown = false;

// Show alert if localStorage is unavailable and alert hasn't been shown
if (!localStorageAvailable && !alertShown) {
  alertShown = true
  alert("localStorage is disabled or unavailable. Please enable it in your browser settings to ensure the application functions correctly.")
}

export const lazyWithRetry = (componentImport) => {
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    )

    try {
      const component = await componentImport()

      window.localStorage.setItem('page-has-been-force-refreshed', 'false')

      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true')
        return window.location.reload()
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })
}
